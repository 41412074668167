
const textSectionCardSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Complete services for you or your business",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Statements/Affidavits and Powers of Attorney",
    textArray: [
        {
            text: "This category comprises from simple statements such as those required for the establishment of companies to complex.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Divorce",
    textArray: [
        {
            text: "When the circumstances so require, divorce can be settled by the notary public, including where the marriage resulted in under aged children.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Contracts",
    textArray: [
        {
            text: "In order to meet your needs, our office offers advice in choosing the type of contract to represent the parties’ negotiation result and reflect their will exactly.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite1 = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Matrimonial Party Agreements",
    textArray: [
        {
            text: "Although the classic matrimonial regime stipulated by the Romanian law is the legal community of property, the property relations between spouses may be `regulated` by signing agreements.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite3 = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Other Notarial Procedures",
    textArray: [
        {
            text: "In this category fall the notarization of copies, the authentication of the translator’s signature, the legal advice, setting a fixed date, the certification of facts, the authentication of signature and of signature specimen.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite2 = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Succesions",
    textArray: [
        {
            text: "Settling a succession is a complex procedure that can be solved effectively following the steps. ",
            classNameText: "text-sm text-white"
        }
    ]
}


const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatures = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3',
    textSection: textSectionCardSection,
    cards: [
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite1,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite2,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite3,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatures;