const dropdown = {
    tagDropdown: "select",
    classNameContainer: "",
    classNameDropdown: "text-white bg-amber-600 px-2 py-1.5 rounded",
    options: [
        {
            value: "RO",
            label: "RO"
        },
        {
            value: "EN",
            label: "EN"
        },
    ]
}


const imageHeader = {
    classnames: "",
    src: "./images/logo.svg",
    width: "80",
    height: "",
    alt: ""
}

const header = {
    classNameContainer : "flex items-center justify-between p-1 md:px-8 lg:px-16 mt-1  shadow-md",
    image: imageHeader,
    dropdown: dropdown
}

export default header;