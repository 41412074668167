import React from 'react';
import GoogleMapReact from 'google-map-react';
import TextSection from '../elements/TextSection';

const AnyReactComponent = ({ Component, text }) => {
    return <Component>{text}</Component>;
};

function Location({ location }) {

  return (
    <div>
        <div id="contact" className={location.classNameTextSection}> {/* Center the standalone TextSection */}
            <TextSection textSection={location.textSection} />
        </div>
        <div className={location.classNameContainer}>
            <iframe frameborder="0"  marginheight="0" marginwidth="0"
                className={location.className}
                src={location.url}
                title={location.title}
                aria-label={location.label}
            ></iframe>
        </div>
    </div>
  );
}

export default Location;
