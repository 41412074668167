
const textSectionCardSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Servicii complete pentru tine sau afacerea ta",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Declaratii si procuri",
    textArray: [
        {
            text: "Aceasta categorie are in componenta de la declaratii simple precum cele necesare la infiintarea societatilor pana la acorduri complexe.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Divort",
    textArray: [
        {
            text: "Atunci cand situatia o impune, divortul poate fi solutionat de catre notarul public, inclusiv in cazul in care din casatorie au rezultat copii minori.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Contracte",
    textArray: [
        {
            text: "Pentru a veni in intampinarea nevoilor dvs., biroul nostru va ofera consultanta pentru alegerea tipului de contract care sa reprezinte.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite1 = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Conventii Matrimoniale",
    textArray: [
        {
            text: "Desi regimul matrimonial clasic prevazut de legislatia romana este cel al comunitatii legale de bunuri.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite3 = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Alte Proceduri Notariale",
    textArray: [
        {
            text: "Pentru a veni in intampinarea nevoilor dvs., biroul nostru va ofera consultanta pentru alegerea tipului de contract care sa reprezinte.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite2 = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Succesiuni",
    textArray: [
        {
            text: "Intra in aceasta categorie legalizarea copiilor, legalizarea semnaturii traducatorului, consultatii juridice, darea de data certa, certificarea unor fapte, legalizarea semnaturii si a specimenului de semnatura. ",
            classNameText: "text-sm text-white"
        }
    ]
}


const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatures = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3',
    textSection: textSectionCardSection,
    cards: [
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite1,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite2,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-amber-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite3,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatures;