import React from 'react';
import Button from '../elements/Button';
import TextSection from '../elements/TextSection';
import Image from '../elements/Image';

function Contact({ contact }) {
  return (
    <div className={contact.classNameContainer}>
      <Image image={contact.image}/>
        {contact.textSections.map((textSection, index) => (
          <TextSection key={index} textSection={textSection} />
        ))}
        {contact.buttons.map((button, index) => (
          <Button key={index} button={button}/>
        ))}

    </div>
  );
}

export default Contact;
