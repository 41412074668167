import React from 'react';

import Image from '../elements/Image';

function ImageShow({ imageShow }) {
  return (
    <div className={imageShow.classNameContainer}>
      {
        imageShow.images.map(image => (
            <Image image={image} />
        ))
      }
    </div>
  );
}

export default ImageShow;
