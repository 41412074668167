const imageContact = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}

const textSectionsContact = [
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mt-8 w-full text-center",
        classNameHeader: "text-white",
        header: "",
        textArray: [
            {
                text: "office@gmail.com",
                classNameText: "text-lg font-semibold text-white"
            },
            {
                text: "+40123456789",
                classNameText: "text-lg my-2 font-semibold text-white"
            },
            {
                text: "MONDAY, TUESDAY, THURSDAY: 09:00 - 17:00",
                classNameText: "text-md font-semibold text-white"
            },
            {
                text: "WEDNESDAY: 10.00 - 19.00",
                classNameText: "text-md font-semibold text-white"
            },
            {
                text: "FRIDAY: 09:00 - 15.00",
                classNameText: "text-md font-semibold text-white"
            }
        ]
    }
]

const buttonsContact = [
    {
        classnames: "",
        tag: "button",
        text: "",
        reference: ""
    }
]

const contact = {
    classNameContainer: "flex flex-col items-center bg-amber-600 rounded-lg mt-6",
    image: imageContact,
    textSections: textSectionsContact,
    buttons: buttonsContact
}

export default contact;