
const textSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Contact",
    textArray: [
        {
            text: "Piața Unirii, Bucharest, Romania",
            classNameText: "text-md text-gray-600"
        }
    ]
}


const location = {
    classNameContainer: "flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 mt-6",
    classNameTextSection: "text-center my-2",
    className: "w-full rounded-xl shadow-md",
    url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.2654862015443!2d26.10079598461888!3d44.427716103872655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff9d16a27373%3A0xf3777fcabd268945!2sPiata%20Unirii!5e0!3m2!1sen!2snl!4v1701131232111!5m2!1sen!2snl",
    textSection: textSection,
    place: "Piața Unirii, Bucharest, Romania",
    title: "Piata Unirii",
    label:  "Piata Unirii",
}

export default location;