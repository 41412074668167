import React, { useState } from 'react';
import Button from '../elements/Button';
import TextSection from '../elements/TextSection';
import Image from '../elements/Image';

function Footer({ footer }) {
  return (
    <div className={footer.classNameContainer}>
        <Image image={footer.image}/>
        <div className="sm:flex justify-between">
          <div className='sm:flex flex-row'>
            {footer.textSections.map( (textSection) => (
              <TextSection textSection={textSection}/>
            ))}
          </div>
        </div>
              
    </div>
  );
}

export default Footer;
