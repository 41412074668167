// Header Data 
import header from "./sectionsEnglish/HeaderData";

// Hero Data
import hero from "./sectionsEnglish/HeroData";

// Card Section Key Features Data
import cardSectionFeatures from "./sectionsEnglish/CardSectionFeaturesData";

import locationData from "./sectionsEnglish/LocationData";

// Text Presentation Images
import imageShow from "./sectionsEnglish/ImageShow";

// Contact Data
import contact from "./sectionsEnglish/ContactData";

// Footer Data
import footer from "./sectionsEnglish/FooterData";

// Final Data for Landing Page
const landinPageData = {
    header: header,
    hero: hero,
    cardSectionFeatures: cardSectionFeatures,
    imageShow: imageShow,
    location: locationData,
    contact: contact,
    footer: footer
}

export default landinPageData;