
const textSectionCardSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Opțiuni Tarife",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFirstBundle = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "49.99 USD + TVA (inclus)",
    textArray: [
        {
            text: "- Beneficiați de o lună gratuită",
            classNameText: "font-semibold text-md text-gray-800 my-2"
        },
        {
            text: "- Acces complet la toate serviciile Effluence",
            classNameText: "font-semibold text-md text-gray-800 my-2"
        }
    ]
}

const textSectionCardSectionSecondBundle = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "59.99 USD + TVA (inclus)",
    textArray: [
        {
            text: "- Beneficiați de o lună gratuită",
            classNameText: "font-semibold text-md text-gray-800 my-2"
        },
        {
            text: "- Crearea website-ului",
            classNameText: "font-semibold text-md text-gray-800 my-2"
        },
        {
            text: "- Serviciu de hosting pentru website",
            classNameText: "font-semibold text-md text-gray-800 my-2"
        },
        {
            text: "- Acces complet la toate serviciile Effluence",
            classNameText: "font-semibold text-md text-gray-800 my-2"
        }
    ]
}

const buttonCardSectionFirstBundle = {
    classnames: "text-white bg-amber-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Incearca acum"
}

const buttonCardSectionSecondBundle = {
    classnames: "text-white bg-amber-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Incearca acum"
}

const imageCardSectionIdScan = {
    classnames: "",
    src: "./images/systemManagement.svg",
    width: "300",
    height: "300",
    alt: ""
}

const imageCardSectionManagement = {
    classnames: "",
    src: "./images/fullManagement.svg",
    width: "300",
    height: "300",
    alt: ""
}

const cardSectionFeatures = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2',
    textSection: textSectionCardSection,
    cards: [
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFirstBundle,
            button: buttonCardSectionFirstBundle,
            image: imageCardSectionIdScan
        },
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionSecondBundle,
            button: buttonCardSectionSecondBundle,
            image: imageCardSectionManagement
        }
    ]
}

export default cardSectionFeatures;