import React, { useState } from 'react';
import Button from '../elements/Button';
import DropDownMenu from '../elements/DropDownMenu';
import Image from '../elements/Image';

import '../../styles/sections/header.css'

function Header({ header }) {
  return (
    <div className={header.classNameContainer}>
      <Image image={header.image} />
      <div className="space-x-4 flex">
        <DropDownMenu dropdown={header.dropdown} />
      </div>
    </div>
  );
}

export default Header;
