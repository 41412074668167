
function Button({ button }) {

  const ComponentButton = button.tag;

  return (
    <a href={button.reference}>
      <ComponentButton className={button.classnames}>
          {button.text}
      </ComponentButton>
    </a>
  );
}

export default Button;
