const imageFooter = {
    classnames: "",
    src: "./images/logo.svg",
    width: "130",   
    height: "",
    alt: ""
}

const textSectionsFooter = [
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mr-4",
        classNameHeader: "font-semibold text-amber-500",
        header: "",
        textArray: [
            {
                text: "",
                classNameText: "text-amber-500"
            }
        ]
    }
]


const footer = {
    classNameContainer: "flex flex-col py-12 px-4 mt-6 sm:px-6 lg:px-8",
    image: imageFooter,
    textSections: textSectionsFooter
}

export default footer;