import React from 'react';
import Button from '../elements/Button';
import Image from '../elements/Image';
import TextSection from '../elements/TextSection';

function Hero({ hero }) {
  return (
    <div className={hero.classNameContainer}>
      <div className="md:flex md:items-center md:justify-between md:mr-4">
        <div className="md:w-1/2 md:order-2">
          <Image image={hero.image} />
        </div>
        <div className="md:w-1/2 md:order-1">
          <TextSection textSection={hero.textSection} />
          <Button button={hero.button} />
        </div>
      </div>
    </div>
  );
}

export default Hero;
