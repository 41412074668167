import React, { useState } from 'react';
import Button from '../elements/Button';
import Image from '../elements/Image';
import TextSection from '../elements/TextSection';

import '../../styles/sections/cardSection.css';

function CardSection({ cardSection }) {
  return (
    <div>  
        <div className={cardSection.classNameTextSection}> {/* Center the standalone TextSection */}
            <TextSection textSection={cardSection.textSection} />
        </div>
        <div className={cardSection.classNameContainer}>
            {
                cardSection.cards.map((card) => (
                    <div className={card.classNameContainer}>
                        {
                            card.image ?
                            (
                                <>
                                    <div className={card.imageContainer}>
                                        <Image image={card.image} />
                                    </div>
                                    <TextSection textSection={card.textSection} />
                                    <Button button={card.button} />
                                </>
                            )
                            :
                            (
                                <>
                                    <TextSection textSection={card.textSection} />
                                    <Button button={card.button} />
                                </>
                            )
                        }
                    </div>
                ))
            }
        </div>
    </div>
  );
}

export default CardSection;
