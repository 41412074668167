import React, {useState, useEffect} from 'react';

// import sections

import Header from '../sections/Header';
import Hero from '../sections/Hero';
import CardSection from '../sections/CardSection';
import ImageShow from '../sections/ImageShow';
import Location from '../sections/Location';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';

// import data if needed

function Landing({landinPageData}) {
  // Fetch data from API endpoint
  // TODO

  return (
    <>
      <Header header={landinPageData.header}/>
      <div className='container mx-auto px-4 md:px-8 lg:px-16 xl:px-20'>
        <Hero hero={landinPageData.hero}/>
        <CardSection cardSection={landinPageData.cardSectionFeatures}/>
        <ImageShow imageShow={landinPageData.imageShow}/>
        <Location location={landinPageData.location} />
        <Contact contact={landinPageData.contact}/>
      </div>
      <Footer footer={landinPageData.footer}/>
    </>
  );
}

export default Landing;
