
function TextSection({ textSection }) {

  const ComponentText = textSection.tagText;
  const ComponentHeader = textSection.tagText;

  return (
    <div className={textSection.classNameContainer}>
        <ComponentHeader className={ textSection.classNameHeader }>
            { textSection.header }
        </ComponentHeader>
        {
          textSection.textArray.map((textElement) => (
            <ComponentText className={textElement.classNameText }>
                {textElement.text }
            </ComponentText>
          ))
        }
    </div>

  );
}

export default TextSection;
