import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

// Landing Page
import Landing from './components/pages/Landing'
import dataInEnglishLanding from './components/data/LandingPageDataEnglish';
import dataInRomanianLanding from './components/data/LandingPageDataRomanian';

// Policies Page
import Policies from './components/pages/Policies'
import dataInEnglishPolicies from './components/data/LandingPageDataEnglish';
import dataInRomanianPolicies from './components/data/LandingPageDataRomanian';

import './App.css';

function App() {

  const [landinPageData, setLandingPageData] = useState(navigator.language.substring(0, 2) === 'ro' ? dataInRomanianLanding : dataInEnglishLanding);
  const [policiesPageData, setPoliciesPageData] = useState(navigator.language.substring(0, 2) === 'ro' ? dataInRomanianPolicies : dataInEnglishPolicies);
  // Detect user's preferred language
  useEffect(() => {
      if (localStorage.getItem("language") != null && localStorage.getItem("language").toLowerCase() === 'ro') {
        setLandingPageData(dataInRomanianLanding); 
        setPoliciesPageData(dataInRomanianPolicies); 
      } else {
        setLandingPageData(dataInEnglishLanding);
        setPoliciesPageData(dataInEnglishPolicies); 
      }
  }, []);
  return (
    <>
      <Routes>
        <Route path ="/" element={<Landing landinPageData={landinPageData} />} />
        <Route path ="/policies" element={<Policies policiesData={policiesPageData} />} />
      </Routes>
    </>
  );
}

export default App;
