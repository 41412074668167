
const textSectionHero = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "my-2",
    classNameHeader: "text-2xl font-bold mb-2",
    header: "Notary Example",
    textArray: [
        {
            text: "Experienced Notary Public, members of the Bucharest Chamber of Civil-Law Notaries, a part of UNNPR (National Union of Notaries Public from Romania)",
            classNameText: "text-lg text-gray-600"
        }
    ]
}

const buttonHero = {
    classnames: "text-white bg-amber-600 px-2 py-2 my-2 rounded hover:bg-amber-400",
    tag: "button",
    text: "Contact us",
    reference: "#contact"
}

const imageHero = {
    classnames: "object-contain rounded-xl mx-4",
    src: "./images/hero.jpg",
    width: "",
    height: "",
    alt: ""
}

const hero = {
    classNameContainer: "py-8 md:py-16 lg:py-20 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0",
    textSection: textSectionHero,
    button: buttonHero,
    image: imageHero
}

export default hero;