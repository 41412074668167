// Header Data 
import header from "./sectionsRomanian/HeaderData";

// Hero Data
import hero from "./sectionsRomanian/HeroData";

// Card Section Key Features Data
import cardSectionFeatures from "./sectionsRomanian/CardSectionFeaturesData";

// Text Presentation Images
import imageShow from "./sectionsEnglish/ImageShow";

import locationData from "./sectionsEnglish/LocationData";

// Card Section Prices
import cardSectionPrices from "./sectionsRomanian/CardSectionPrices";

// Contact Data
import contact from "./sectionsRomanian/ContactData";

// Footer Data
import footer from "./sectionsRomanian/FooterData";

// Final Data for Landing Page
const landinPageData = {
    header: header,
    hero: hero,
    cardSectionFeatures: cardSectionFeatures,
    imageShow: imageShow,
    location: locationData,
    cardSectionPrices: cardSectionPrices,
    contact: contact,
    footer: footer
}

export default landinPageData;