import React from "react";

function DropdownMenu({ dropdown }) {
    const DropdownComponent = dropdown.tagDropdown;

    // Define a function to handle the change event
    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        // Call the valueChange function with the selected value
        localStorage.setItem("language", selectedValue);
        window.location.reload();
    };

    return (
        <div className={dropdown.classNameContainer}>
            <DropdownComponent
                className={dropdown.classNameDropdown}
                onChange={handleDropdownChange} // Attach the event handler
            >
                {dropdown.options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </DropdownComponent>
        </div>
    );
}

export default DropdownMenu;
