const imageOne = {
    classnames: "object-contain rounded-xl",
    src: "./images/pictureOne.jpg",
    width: "",
    height: "",
    alt: ""
}
const imageTwo = {
    classnames: "object-contain rounded-xl",
    src: "./images/pictureTwo.jpg",
    width: "",
    height: "",
    alt: ""
}
const imageThree = {
    classnames: "object-contain rounded-xl",
    src: "./images/pictureThree.jpg",
    width: "",
    height: "",
    alt: ""
}
const imageFour = {
    classnames: "object-contain rounded-xl",
    src: "./images/pictureFour.jpg",
    width: "",
    height: "",
    alt: ""
}

const imageShow = {
    classNameContainer: 'my-12 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 p-6',
    images: [
        imageOne,
        imageTwo,
        imageThree,
        imageFour
    ]
}

export default imageShow;